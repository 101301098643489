<!-- 进修招聘  推荐内容管理 邓 -->
<template>
    <div style="margin: -15px -15px; background-color: #f0f2f5">
      <div class="datePicker">
        <div class="body">
          <a-table
            :columns="columns"
            :data-source="tableList"
            :pagination="false"
          >
              <template slot="chineseName" slot-scope="text, row, index">
                <span @click="openLink(row)">{{ text }}</span>
              </template>
          
            <span slot="sort" slot-scope="text, record, index">
              <div  
                style="
                  display: flex;
                  justify-content: start;
                  width: 100%;
                  margin: auto;
                  margin-left: 30px;
                "
              >
                <!-- 第一页的第一个 -->
                <a v-if="index === 0 && curPage == 1" @click="move('down', record.id)">下移</a>
                <!-- 是最后一页的最后一个 -->
                <a v-else-if="index === tableList.length -1 && curPage == Math.ceil(total / pageSize)" @click="move('up', record.id)">上移</a>
                <span v-else>
                  <a @click="move('up', record.id)">上移</a>
                  <a @click="move('down', record.id)" style="margin-left:5px">下移</a>
                </span>
              </div>
            </span>
            <span slot="operation" slot-scope="text, record, index">
              <a-popconfirm
                title="确定取消推荐?"
                ok-text="是"
                cancel-text="否"
                @confirm="del(record.formulaId)"
              >
                <a>取消推荐</a>
              </a-popconfirm>
            </span>
          </a-table>
          <div
            style="
              margin-top: 28px;
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <span
              style="
                font-size: medium;
                color: #929292;
                font-size: 14px;
                bottom: 3%;
              "
            >
              共{{ total }}条记录 第 {{ curPage }} /
              {{ Math.ceil(total / pageSize) }} 页
            </span>
            <a-pagination
              style="float: right"
              show-quick-jumper
              show-size-changer
              :page-size.sync="pageSize"
              :total="total"
              v-model="curPage"
              @change="pageChange"
              @showSizeChange="pageChange"
            />
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import {
    getRecommendList,
  } from "@/service/trainManage_api";
  import { getRecommend, moveRecommendList,delRecommendList } from "@/service/MedicalFormulas_api";
  export default {
    data() {
      return {
        tableList: [],
        total: 0,
        curPage: 1,
        pageSize: 10,
        columns: [
        {
          title: "code",
          dataIndex: "code",
          align: "center",
        },
        // {
        //   title: "所属类别",
        //   scopedSlots: { customRender: "columnName" },
        //   align: "center",
        // },
        {
          title: "中文名称",
          dataIndex: "chineseName",
          scopedSlots: { customRender: "chineseName" },
          align: "center",
        },
        {
          title: "英文名称",
          dataIndex: "englishName",
          align: "center",
        },
        // {
        //   title: "发布人",
        //   dataIndex: "issueName",
        // },
        // {
        //   title: "发布时间",
        //   dataIndex: "issueTime",
        // },
        {
          title: "排序",
          dataIndex: "sort",
          width: "10%",
          scopedSlots: { customRender: "sort" },
          align: "center",
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "operation" },
          align: "center",
        },
      ],
      };
    },
    created() {
      this.load();
    },
    methods: {
      openLink(row) {
        // window.open(
        //   `${this.formulaHeader}/formula/${row.code}?memberId=c2d242a0-f979-11ea-aed5-33e01bcc3a0a&channel=admin&location=YXGS_ADMIN&id=${row.id}`
        // );
      },
      async load() {
        let data = {
          pageNo:this.curPage,
          pageSize:this.pageSize
        }
        const res = await getRecommend(data);
        if (res.code == 0) {
          this.tableList = res.data;
          this.total = res.count;
          // console.log("数据",res.data)
        }
      },
      async move(a, id) {
        const res = await moveRecommendList(a, id);
        if (res.code == 0) {
          this.$message.success("操作成功");
          this.load();
        }
      },
      async del(id) {
        const res = await delRecommendList(id);
        if (res.code == 0) {
          this.load();
          this.$message.success("取消推荐成功");
        }
      },
      // 分页查询
      pageChange(curPage, pageSize) {
        this.curPage = curPage;
        this.pageSize = pageSize;
        this.load();
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  .datePicker {
    background-color: #fff;
    width: 100%;
    padding: 20px;
  }
  .body {
    background-color: #fff;
    display: flex;
    flex-flow: column;
  }
  ::v-deep .ant-table-thead > tr > th {
    font-weight: bold;
  }
  </style>
  